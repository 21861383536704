import $, { get } from "jquery";

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require("./lib/jquery.migrate");
require("foundation-sites");
require("waypoints/lib/jquery.waypoints");
require("./lib/jquery.magnific-popup");
require("./lib/imagesloaded.min");

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document)
  .foundation()
  .ready(function () {
    if (location.hash) {
      window.scrollTo(0, 0);
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);

      setTimeout(function () {
        if ($(location.hash).length) {
          var offset = $(location.hash).offset().top;
          if ($(window).width() <= 1024) {
            offset -= 60;
          }
          $("html, body").animate({ scrollTop: offset }, 500);
        }
      }, 100);
    }

    $(document)
      .imagesLoaded()
      .done(function () {
        Waypoint.refreshAll();

        $(".lazy").each(function () {
          var src = $(this).data('image');
          var $lazy = $(this);
          var image = $(this).find('img').first();
          image.after($('<img src="' + src + '" class="highres">'));
          $lazy.imagesLoaded().done(function () {
            setTimeout(function () {
              $lazy.find('.highres').addClass('ready');
              if ($lazy.attr('id') == 'master-plan') {
                masterPlan();
              }
              setTimeout(function () {
                image.addClass('hide');
              }, 500);
            }, 1000);
          })
        });
      });

    $("#hero").imagesLoaded().done(function () {
      setTimeout(function () {
        $("#hero-ready").addClass("ready");
      }, 1000);
    });

    $(".btn-register").on('click', function () {
      $("#register").fadeIn();
      $("html, body").addClass("popup");
      if ($("header").hasClass('menu-open')) {
        $('#hamburger, #mobile-menu').removeClass('active');
        $("html, body, header, .main").removeClass('menu-open');
      }
    });

    $("#register .close").on('click', function () {
      $("#register").fadeOut(function () {
        $("html, body").removeClass("popup");
      });
    });

    $(".floor-plate-container .floor-plate, .floor-plan-container .floor-plan").on('click', function () {
      if (localStorage.getItem('cade_email')) {
        window.open($(this).data('pdf'));
      } else {
        $("#register-plan input[name=url]").val($(this).data('pdf'));
        $("#register-plan").fadeIn();
        $("html, body").addClass("popup");
      }
    });

    $("#register-plan .close").on('click', function () {
      $("#register-plan").fadeOut(function () {
        $("html, body").removeClass("popup");
      });
    });

    $("nav a, #mobile-menu a").on("click", function (e) {
      if ($(this).attr('href') && $(this).attr('href').includes('#')) {
        var path = $(this).attr('href').split("#")[0];
        if (path == window.location.pathname) {
          var target = '#' + $(this).attr('href').split("#")[1];
          if ($(target).length) {
            e.preventDefault();
            if ($("header").hasClass('menu-open')) {
              $('#hamburger, #mobile-menu').removeClass('active');
              $("html, body, header, .main").removeClass('menu-open');
            }

            var offset = $(target).offset().top;
            if ($(window).width() <= 1024) {
              offset -= 60;
            }

            $("html, body").animate({ scrollTop: offset }, 500);
          }
        }
      }
    });

    function masterPlan() {
      positionHotSpots();

      var $masterPlan = $("#master-plan");
      $masterPlan.find('.mp-legends, #mp-stage-one, #mp-stage-two, #mp-dots').addClass('show');
    }

    function positionHotSpots() {
      var imageWidth = 1920,
        imageHeight = 1516,
        imageAspectRatio = imageWidth / imageHeight;

      var windowWidth = $("#master-plan").width(),
        windowHeight = $("#master-plan").height(),
        windowAspectRatio = windowWidth / windowHeight;

      if (windowAspectRatio > imageAspectRatio) {
        var ratio = windowWidth / imageWidth;
      } else {
        var ratio = windowHeight / imageHeight;
      }

      $("#mp-dots .mp-dot").each(function () {
        var xPos = ($(this).data("x") * ratio) + "px";
        var yPos = ($(this).data("y") * ratio) + "px";

        $(this).css({
          "transform": "translate3d(" + xPos + "," + yPos + ", 0)"
        }).addClass("ready");
      });
      $(".stages .stage").addClass("ready");
    }

    $(".mp-dot").on("mouseover", function () {
      if ($(this).hasClass('ready')) {
        $("#master-plan").find($(this).data('target')).addClass('show');
        $(this).addClass('hide');
      }
    });

    $(".mp-dot").on("mouseout", function () {
      if ($(this).hasClass('ready')) {
        $("#master-plan").find($(this).data('target')).removeClass('show');
        $(this).removeClass('hide');
      }
    });

    $(".stages .stage").on("mouseover", function () {
      if ($(this).hasClass('ready')) {
        $("#master-plan").find($(this).data('target')).addClass('show');
        $(".mp-dot[data-target='" + $(this).data('target') + "']").addClass('hide');
      }
    });

    $(".stages .stage").on("mouseout", function () {
      if ($(this).hasClass('ready')) {
        $("#master-plan").find($(this).data('target')).removeClass('show');
        $(".mp-dot[data-target='" + $(this).data('target') + "']").removeClass('hide');
      }
    });

    $("select").on('change', function () {
      if ($(this).val()) {
        $(this).addClass('has-value');
      }
    });

    $(window).on("resize", function () {
      positionHotSpots();
    });

    $(".ajax-popup").magnificPopup({
      type: "ajax",
    });

    $(".animate-container").waypoint(
      function (direction) {
        if (direction == "down") {
          $(this.element).find(".animate").addClass("ready");
        }
      },
      {
        offset: "80%",
      }
    );

    $(".animate-container").each(function () {
      var i = 0
      $(this).find('.animate').each(function () {
        $(this).addClass("animate-delay-" + i);
        i++;
      })
    });

    var lastScrollTop = $(window).scrollTop();
    $(window).on('scroll', function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop >= 107) {
        $("header").addClass("scrolled");

        if (scrollTop < lastScrollTop) {
          // up
          $("header").addClass("up");
        } else {
          // down
          $("header").removeClass("up");
        }
      } else {
        $("header").removeClass("scrolled");
      }
      lastScrollTop = scrollTop;
    });

    $("#hamburger").on('click', function () {
      $(this).toggleClass('active');
      $("#mobile-menu").toggleClass('active');
      $("html, body, header, .main").toggleClass('menu-open');
    });

    // register login
    $("#register-plan-login-form").on("submit", function (e) {
      e.preventDefault();
      var form = $(this);
      var email = form.find("input[name=email]").val();
      var password = form.find("input[name=password]").val(); //honey    
      var url = form.find("input[name=url]").length ? form.find("input[name=url]").val() : '';


      var formData = {
        email: email,
        password: password
      };

      form.find(".form-info").text("").fadeOut();
      form.find("button").prop("disabled", true).addClass("disable");

      $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: formData,
        dataType: 'json',
        success: function (res) {
          if (res == 1) {
            var text = 'Logged in successfully. You will get redirected soon.';

            form.find(".form-info").text(text).fadeIn();
            form.find("input").val("");
            form.find("input[type=submit]").val("Login");
            form.find("button").prop("disabled", false).addClass("disable");
            localStorage.setItem('cade_email', email);

            setTimeout(function () {
              if (url) {
                window.open(url);
              }
              $("#register-plan").fadeOut(function () {
                $("html, body").removeClass("popup");
              });
            }, 2000);
          } else {
            form.find(".form-info")
              .text("Something went wrong, please try again later!")
              .fadeIn();
          }
        },
        error: function (res) {
          form.find(".form-info")
            .text("Something went wrong, please try again later!")
            .fadeIn();
        },
      });
    })
    // register form
    $("#register-form, #register-plan-form").on("submit", function (e) {
      e.preventDefault();
      var form = $(this);
      var name = form.find("input[name=name]").val();
      var last = form.find("input[name=last]").val();
      var phone = form.find("input[name=phone]").val();
      var email = form.find("input[name=email]").val();
      var how = form.find("select[name=how]").length ? form.find("select[name=how]").val() : '';
      var postcode = form.find("input[name=postcode]").val(); //honey      
      var url = form.find("input[name=url]").length ? form.find("input[name=url]").val() : '';

      var formData = {
        name: name,
        last: last,
        phone: phone,
        email: email,
        how: how,
        postcode: postcode,
      };

      form.find(".form-info").text("").fadeOut();
      form.find("button").prop("disabled", true).addClass("disable");

      $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: formData,
        dataType: 'json',
        success: function (res) {
          if (res == 1) {
            var text = 'Thank you for registering your interest with us.';

            if (url) {
              text += ' You will get redirected soon.';
            }

            form.find(".form-info").text(text).fadeIn();
            form.find("input").val("");
            form.find("button").prop("disabled", false).addClass("disable");
            localStorage.setItem('cade_email', email);

            setTimeout(function () {
              if (url) {
                window.open(url);
              }
              $("#register, #register-plan").fadeOut(function () {
                $("html, body").removeClass("popup");
              });
            }, 2000);
          } else {
            form.find(".form-info")
              .text("Something went wrong, please try again later!")
              .fadeIn();
          }
        },
        error: function (res) {
          form.find(".form-info")
            .text("Something went wrong, please try again later!")
            .fadeIn();
        },
      });
    });
  });
